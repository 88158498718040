// extracted by mini-css-extract-plugin
export var action = "m";
export var content = "h";
export var heading = "k";
export var icon = "l";
export var points = "i";
export var popup = "n";
export var prizeImage = "o";
export var root = "f";
export var text = "j";
export var title = "g";