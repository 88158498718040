// extracted by mini-css-extract-plugin
export var copyDark = "hb";
export var copyLight = "ib";
export var disabledDark = "mb";
export var disabledLight = "lb";
export var error = "kb";
export var grey = "gb";
export var primary1 = "Xa";
export var primary2 = "Ya";
export var primary3 = "Za";
export var primary4 = "_a";
export var primary5 = "ab";
export var pure = "Va";
export var secondary1 = "bb";
export var secondary2 = "cb";
export var secondary3 = "db";
export var secondary4 = "eb";
export var secondary5 = "fb";
export var styled = "Wa";
export var success = "jb";