// extracted by mini-css-extract-plugin
export var animated = "ac";
export var backdrop = "_b";
export var body = "jc";
export var box = "bc";
export var close = "hc";
export var closeIcon = "ic";
export var floating = "cc";
export var heading = "dc";
export var scrollBody = "kc";
export var text = "gc";
export var title = "ec";
export var withText = "fc";