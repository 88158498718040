// extracted by mini-css-extract-plugin
export var icon = "Ba";
export var iconItem = "Aa";
export var icons = "Ca";
export var link = "Da";
export var linkCopyDark = "Pa";
export var linkCopyLight = "Qa";
export var linkDisabledDark = "Ua";
export var linkDisabledLight = "Ta";
export var linkError = "Sa";
export var linkGrey = "Oa";
export var linkPrimary1 = "Ea";
export var linkPrimary2 = "Fa";
export var linkPrimary3 = "Ga";
export var linkPrimary4 = "Ha";
export var linkPrimary5 = "Ia";
export var linkSecondary1 = "Ja";
export var linkSecondary2 = "Ka";
export var linkSecondary3 = "La";
export var linkSecondary4 = "Ma";
export var linkSecondary5 = "Na";
export var linkSuccess = "Ra";