// extracted by mini-css-extract-plugin
export var apply = "y";
export var applyContainer = "z";
export var aside = "w";
export var backdrop = "r";
export var banner = "u";
export var carousel = "v";
export var container = "p";
export var content = "q";
export var socials = "x";
export var text = "s";
export var title = "t";