// extracted by mini-css-extract-plugin
export var copyDark = "zb";
export var copyLight = "Ab";
export var disabledDark = "Eb";
export var disabledLight = "Db";
export var error = "Cb";
export var grey = "yb";
export var icon = "Fb";
export var primary1 = "ob";
export var primary2 = "pb";
export var primary3 = "qb";
export var primary4 = "rb";
export var primary5 = "sb";
export var root = "nb";
export var secondary1 = "tb";
export var secondary2 = "ub";
export var secondary3 = "vb";
export var secondary4 = "wb";
export var secondary5 = "xb";
export var success = "Bb";
export var text = "Gb";