// extracted by mini-css-extract-plugin
export var highlight = "xc";
export var highlightCopyDark = "Jc";
export var highlightCopyLight = "Kc";
export var highlightDisabledDark = "Oc";
export var highlightDisabledLight = "Nc";
export var highlightError = "Mc";
export var highlightGrey = "Ic";
export var highlightPrimary1 = "yc";
export var highlightPrimary2 = "zc";
export var highlightPrimary3 = "Ac";
export var highlightPrimary4 = "Bc";
export var highlightPrimary5 = "Cc";
export var highlightSecondary1 = "Dc";
export var highlightSecondary2 = "Ec";
export var highlightSecondary3 = "Fc";
export var highlightSecondary4 = "Gc";
export var highlightSecondary5 = "Hc";
export var highlightSuccess = "Lc";