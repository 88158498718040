// extracted by mini-css-extract-plugin
export var field = "Hb";
export var outlineCopyDark = "Tb";
export var outlineCopyLight = "Ub";
export var outlineDisabledDark = "Yb";
export var outlineDisabledLight = "Xb";
export var outlineError = "Wb";
export var outlineGrey = "Sb";
export var outlinePrimary1 = "Ib";
export var outlinePrimary2 = "Jb";
export var outlinePrimary3 = "Kb";
export var outlinePrimary4 = "Lb";
export var outlinePrimary5 = "Mb";
export var outlineSecondary1 = "Nb";
export var outlineSecondary2 = "Ob";
export var outlineSecondary3 = "Pb";
export var outlineSecondary4 = "Qb";
export var outlineSecondary5 = "Rb";
export var outlineSuccess = "Vb";
export var text = "Zb";